import React from "react";

function Flags(props) {
  const countries = props.countries;
  let i = 0;
  const flags = countries.map(
    (country) =>
        i++ < (props.maxFlags || 99) && country.countryCode && (
        <img
          key={country.countryCode}
          src={`https://flagcdn.com/${country.countryCode.toLowerCase()}.svg`}
          alt={country.countryName}
          title={country.countryName}
        />
      )
  );

  return <>{flags}</>;
}

export default Flags;
