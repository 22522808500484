import Cookies from "universal-cookie";

const cookies = new Cookies();

export function cookieGetFilterRange() {
    return cookies.get("filter-range");
}

export function cookieSetFilterRange(range) {
    cookies.set("filter-range", range, { path: "/" });
}