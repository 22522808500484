import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MoviesPage from "./components/movies-page";
import HomePage from "./components/home-page";
import Navigation from "./components/navigation";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Router>
        <div className="container">
          <div className="column-menu">
            <Navigation />
          </div>
          <div id="movies-container" className="column-content">
            <Routes>
              <Route
                key="page-today"
                path="/dnes"
                element={
                  <MoviesPage
                    endpoint="/s-movies"
                    title="V kinech"
                    showRange={true}
                  />
                }
              />
              <Route
                key="page-premieres"
                path="/novinky"
                element={
                  <MoviesPage
                    endpoint="/s-premieres"
                    title="Tipy pro náročné"
                    premiereVisible={true}
                  />
                }
              />
              <Route
                key="page-rares"
                path="/speciality"
                element={
                  <MoviesPage
                    endpoint="/s-rares"
                    title="Obnovené premiéry a rarity"
                  />
                }
              />
              <Route
                key="page-all"
                path="/vse"
                element={
                  <MoviesPage
                    endpoint="/s-all"
                    title="Všechno co máme"
                  />
                }
              />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
