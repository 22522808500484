import React from "react";

function PerformanceFlags(props) {
  const { flags } = props;

  return (
    <ul className="perf-flags">
      [
      {flags.map((flag) => (
        <li key={flag} className="perf-flag">{_getText(flag)}</li>
      ))}
      ]
    </ul>
  );
}

function _getText(flag) {
  switch (flag) {
    case "D":
      return "Dab.";
    case "3D":
      return "3D";
    case "T":
      return "Tit.";
    case "OCZ":
      return "Orig.";
    default:
      return "";
  }
}

export default PerformanceFlags;
