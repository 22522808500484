import React from 'react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Popup from 'reactjs-popup';
import Navigation from './navigation';

function MobileNavigation() {
    const location = useLocation();
    const closePopupRef = useRef(null);

    useEffect(() => {
        if (closePopupRef.current) {
            closePopupRef.current();
        }        
    }, [location]);

    return (
        <Popup
            trigger={<button id="mobile-navigation" className="sort-label">
                <svg width="1em" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: "var(--highlight-color)" }}>
                    <path d="M3 12H21M3 6H21M3 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
            }
            modal
            lockScroll
            closeOnDocumentClick
            closeOnEscape
        >
            {
                close => {
                    closePopupRef.current = close;
                    return (<Navigation />)
                }
            }
        </Popup>
    )
}

export default MobileNavigation;