import React from "react";
import getGenres from "../utilities/genres.js";

function Genres(props) {
  const { genres } = props;

  if (!genres) {
    return null;
  }

  const genreList = window.genres;

  if (!genreList) {
    getGenres();
    return null;
  }

  genres.sort();

  return (
    <ul className="genres">
      {genres.map((genre) => (
        <li key={genre} className="genre">
          {genreList[genre]}
        </li>
      ))}
    </ul>
  );
}

export default Genres;
