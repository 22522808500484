import React, { useState, useEffect, useRef } from "react";
import ReactSlider from "react-slider"

const PerformanceTime = ({ min, max, range, minMaxTrigger, onRangeChange }) => {
  const [timeRange, setTimeRange] = useState({ from: min, to: max });

  const handleSliderChange = (value) => {
    setTimeRange({ from: value, to: timeRange.to });
    debouncedCallHandler(value);
  };

  const callHandler = (value, index) => {
    const range = { from: value, to: max };
    onRangeChange(range);
  };

  const debouncedCallHandler = useRef(debounce(callHandler, 500)).current; 

  useEffect(() => {
    if (range) {
      const parsedRange = parseRange(range, min, max, timeRange);
      if (parsedRange.from !== timeRange.from || parsedRange.to !== timeRange.to) {
        setTimeRange({ from: parsedRange.from, to: parsedRange.to });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, minMaxTrigger]);

  return (
    <div className="performance-range">
      <span>od</span>
      <ReactSlider
        onAfterChange={handleSliderChange}
        value={timeRange.from}
        min={min}
        max={max}
        renderThumb={({key, ...props}, state) => <div key={key} {...props}><span>{state.valueNow}:00</span></div>}
        className="slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        />
    </div>
  );
};

export default PerformanceTime;

const parseRange = (range, min, max, timeRange) => {
  const rng = range.split("-");
  if (rng.length === 2) {
    const parsedFrom = parseInt(rng[0]);
    const parsedTo = parseInt(rng[1]);

    if (parsedTo >= parsedFrom && parsedFrom >= min && parsedTo <= max) {
      return { from: parsedFrom, to: parsedTo };
    }
  }
  return timeRange;
};

const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};