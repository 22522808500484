import React from "react";

function Release(props) {
  const released = props.released;

  if (released) {
    return <div className="movie-release" title="Natočeno">{released}</div>;
  } else {
    return null;
  }
}

export default Release;
