import React from "react";
import Rating from "./rating-big";

function RatingBigList(props) {
  return (
    <>
      {props.rating ? (
        <ul className="rating-list-big">
          {props.rating.map((rating) => (
            <li key={rating.database}>
              <Rating rating={rating} />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
}

export default RatingBigList;
