import React, { useCallback } from "react";
import Rating from "./rating";

function RatingList(props) {
  const handleClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      {props.rating ? (
        <ul className="rating-list" onClick={handleClick}>
          {props.rating.map((rating) => (
            <li key={rating.database}>
              <Rating rating={rating} />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
}

export default RatingList;
