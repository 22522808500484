import React from "react";
import moment from "moment";

function Premiere(props) {
  const text = _getPremiereText(props.date);

  return (
    <>
      {text ? (
        <div className="movie-premiere" title="Premiéra v ČR">
          {text}
        </div>
      ) : null}
    </>
  );
}

function _getPremiereText(date) {
  if (!date) {
    return null;
  }

  const mDate = moment(date, "YYYY-MM-DD");
  const now = moment();

  const inDays = parseInt(moment.duration(mDate.diff(now)).asDays());

  if (inDays < 0) {
    return null;
  }

  return `${
    mDate.year() === now.year()
      ? mDate.format("DD.MM.")
      : mDate.format("DD.MM.YYYY")
  } +${inDays}`;
}

export default Premiere;
