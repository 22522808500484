import React from "react";

function SortButton(props) {
  return (
    <button
      className="sort-label"
      value={props.value}
      onClick={props.onClick}
    >
      <img
        src={process.env.PUBLIC_URL + "/img/" + props.img}
        alt={props.value}
      />
      <span className="sort-button-text">{props.value}</span>
    </button>
  );
}

export default SortButton;
