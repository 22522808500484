import React from "react";

function Credits(props) {
  const credits = props.credits;

  if (credits && credits.length > 0) {
    const crds = credits.map((credit) => (
      <li key={credit.title} className="credit">
        <span className="credit-title">{credit.title}:</span>{"\u00A0"}
        <span className="credit-name">{credit.name}</span>
      </li>
    ));

    return <ul className="movie-datail-credits">{crds}</ul>;
  }

  return null;
}

export default Credits;
