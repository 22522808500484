const getGenres = () => {
  if (!window.genres) {
    return fetch("/s-genres")
      .then((result) => result.json())
      .then((json) => {
        window.genres = json;
        return window.genres;
      });
  } else {
    return new Promise((resolve, reject) => {
      resolve(window.genres);
    });
  }
};

export default getGenres;
