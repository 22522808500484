import React, { useImperativeHandle, forwardRef, useState, useEffect, useRef } from "react";
import RatingBigList from "./rating-big-list.js";
import Flags from "./flags.js";
import { prominent } from "color.js";
import Credits from "./credits.js";
import ShowMoreText from "react-show-more-text";
import Performances from "./performance-list.js";
import CloseButton from "./close-button.js";
import PremiereDetail from "./premiere-detail.js";
import Overlay from "./overlay";
import Genres from "./genres";
import { neutralizeBack, revivalBack } from "../utilities/modal";
import { cookieGetFilterRange } from "../utilities/cookie-store";
import RangeInfo from "./movie-detail-range-info";
import YouTubeTrailers from "./trailers.js";

const MovieDetail = forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [homepage, setHomepage] = useState(false);
  const [runtime, setRuntime] = useState(false);
  const [movieName, setMovieName] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [plot, setPlot] = useState(null);
  const [rating, setRating] = useState(null);
  const [released, setReleased] = useState(null);
  const [countries, setCountries] = useState([]);
  const [backdropUrl, setBackdropUrl] = useState(null);
  const [performances, setPerformances] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [prominentColor, setProminentColor] = useState(null);
  const [isBackdropLight, setIsBackdropLight] = useState(false);
  const [credits, setCredits] = useState([]);
  const [latestCzechPremiere, setLatestCzechPremiere] = useState(null);
  const [uniqueFlags, setUniqueFlags] = useState(null);
  const [genres, setGenres] = useState(null);
  const [filterRange, setFilterRange] = useState({ from: 0, to: 24 });
  
  const modalElement = useRef(null);
  
  const prominentColorString = prominentColor ? prominentColor.join(", ") : "0, 0, 0";
  const opacity = prominentColor ? "0.75" : "0.9";
  const range = props.applyPerformanceFilter ? filterRange : { from: 0, to: 24 };

  useEffect(() => {
    modalElement.current = document.querySelector(".modal");
  }, []);

  useImperativeHandle(ref, () => ({
    showMovie(id) {
      setIsLoading(true);
      showModal();
      fetchMovieDetail(id);
    }
  }));

  const showModal = () => {
    neutralizeBack(handleBrowserBack);
    modalElement.current.style.display = "block";
    document.documentElement.style.overflow = "hidden";
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        hideModal();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);      

  const hideModal = () => {
    setTrailers([]);
    revivalBack();
    modalElement.current.style.display = "none";
    document.documentElement.style.overflow = "";
  };

  const handleBrowserBack = () => hideModal();

  const modalClicked = (event) => {
    if (
      event.target.classList.contains("modal") ||
      event.target.classList.contains("movie-detail")
    ) {
      hideModal();
    }
  };

  const fetchMovieDetail = (id) => {
    fetch(`/s-movie-detail/${id}`)
      .then((result) => result.json())
      .then((json) => {
        if (json.backdropUrl) {
          fetch(json.backdropUrl)
            .catch(() => {})
            .finally(() => {
              setMovieDetail(json);
            });
        } else {
          setMovieDetail(json);
        }
      });
  };

  const setMovieDetail = (json) => {
    if (json.backdropUrl) {
      prominent(json.backdropUrl, { amount: 1 })
        .then((color) => {
          setFromJson(json, color);
        })
        .catch(() => {
          setFromJson(json);
        });
    } else {
      setFromJson(json);
    }
  };

  const setFromJson = (json, color = null) => {
    let isLight = false;

    if (color) {
      const [r, g, b] = color;
      isLight =
        Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) > 128.0;
    }

    const range = cookieGetFilterRange() ?? "7-24";

    setMovieName(json.czechName);
    setOriginalName(json.originalName);
    setBackdropUrl(json.backdropUrl);
    setPlot(json.plot);
    setRating(json.rating.ratings);
    setReleased(json.year);
    setCountries(json.countries);
    setProminentColor(color);
    setIsBackdropLight(isLight);
    setCredits(json.credits);
    setPerformances(json.performances || []);
    setTrailers(json.youTubeTrailers || []);
    setLatestCzechPremiere(json.latestCzechPremiere);
    setIsLoading(false);
    setUniqueFlags(json.uniqueFlags);
    setGenres(json.genres);
    setHomepage(json.homepage);
    setRuntime(json.runtime);
    setFilterRange(getRange(range));
  };

  const getRange = (range) => {
    const times = range.split("-");
    return { from: parseInt(times[0]), to: parseInt(times[1]) };
  };

  const getMovieTitle = () => {
    return (
      <>
        {homepage ? (
          <a href={homepage} target="_blank" rel="noopener noreferrer">
            {getPlainTitle()}
          </a>
        ) : (
          getPlainTitle()
        )}
      </>
    );
  };

  const getPlainTitle = () => (
    <h1 className="movie-detail-title">{movieName}</h1>
  );

  return (
    <div className="modal" onClick={modalClicked}>
      <Overlay isDisplayed={isLoading} />
      {!isLoading && (
        <section
          className="movie-detail"
          style={{
            backgroundImage: `linear-gradient(rgba(${prominentColorString}, ${opacity}), rgba(${prominentColorString}, ${opacity})), url(${backdropUrl})`,
            color: isBackdropLight ? "black" : "white",
          }}
        >
          <CloseButton onClose={hideModal} />
          <div className="movie-content">
            <div>{getMovieTitle()}</div>
            <div className="movie-flags">
              {originalName !== movieName && <h2>{originalName}</h2>}
              {released && (
                <span className="movie-detail-released">
                  {"\u00A0"}{released}
                </span>
              )}              
              {runtime && (
                <span className="movie-detail-runtime">
                  {"\u00A0"}
                  {runtime}
                  {"\u00A0minut"}
                </span>
              )}
              <Flags countries={countries} />
            </div>
            <Genres genres={genres} />
            <RatingBigList rating={rating} />
            <Credits credits={credits} />
            <div className="movie-detail-plot">
              {plot && (
                <>
                  <ShowMoreText lines={30} more="⊕" less="⊖" truncatedEndingComponent="... ">
                    {plot}
                  </ShowMoreText>
                </>
              )}
            </div>
            {performances.length < 1 && (
              <PremiereDetail date={latestCzechPremiere} />
            )}
            {trailers && trailers.length > 0 && (
              <YouTubeTrailers trailers={trailers} />
            )}
            {performances.length > 0 && (
              <>
                <RangeInfo range={range} />
                <Performances
                  performances={performances}
                  uniqueFlags={uniqueFlags}
                  highlightedDate={props.contextDate}
                  range={range}
                />
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
});

export default MovieDetail;
