import React from "react";

const CloseButton = ({ onClose }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <button className="movie-detail-close" onClick={handleClose}>
      ⮐
    </button>
  );
};

export default CloseButton;
