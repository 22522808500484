import React, { useCallback } from "react";

const SortSwitch = ({ active, ascending, value, img, onClick }, ref) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClick(e);
    },
    [onClick]
  );

  return (
    <button className="sort-label" value={value} onClick={handleClick}>
      <img src={process.env.PUBLIC_URL + "/img/" + img} alt={value} />
      {active && (
        <img
          className="sort-arrow"
          src={
            process.env.PUBLIC_URL +
            (ascending ? "/img/arrow-up.svg" : "/img/arrow-down.svg")
          }
          alt={ascending ? "Seřazeno vzestupně" : "Seřazeno sestupně"}
        />
      )}
    </button>
  );
};

export default SortSwitch;
