import React, { useState } from "react";
import Performance from "./performance.js";
import moment from "moment";
import Switch from "./switch";

function Performances(props) {
  const { performances, uniqueFlags, range } = props;

  const [subtitles, setSubtitles] = useState(true);
  const [c3d, setC3d] = useState(true);
  const [dubbing, setDubbing] = useState(true);
  const [originalCz, setOriginalCz] = useState(true);

  _filterPerformances(props.performances);

  const now = moment().startOf("day");
  const today = now.startOf("day").format("YYYY-MM-DD");
  const highlightedDate = props.highlightedDate ?? today;

  const perfs = performances.map((day) => {
    const todayClass =
      highlightedDate === day.date ? "movie-detail-perf-today" : "";

    const mDay = moment(day.date).startOf("day");

    if (today === mDay.format("YYYY-MM-DD")) {
      day.relativeDate = "dnes";
    } else if (today === mDay.subtract(1, "days").format("YYYY-MM-DD")) {
      day.relativeDate = "zítra";
    } else {
      const mdate = moment(day.date);
      const inDays = parseInt(moment.duration(mdate.diff(now)).asDays());

      if (inDays < 5) {
        day.relativeDate = `za ${inDays} dny`;
      } else {
        day.relativeDate = `za ${inDays} dní`;
      }
    }
    return (
      <div key={`perf-day-${day.date}`} className="movie-detail-perf-block">
        <span className={`movie-detail-perf-day ${todayClass}`}>
          {day.day} / {day.relativeDate}
        </span>
        <span className="movie-detail-perf-date">
          {day.dateCz.substring(0, 6)}
        </span>
        {_day(day, subtitles, c3d, dubbing, originalCz, range)}
      </div>
    );
  });

  return (
    <>
      {_renderSwitches(
        setSubtitles,
        setDubbing,
        setC3d,
        setOriginalCz,
        uniqueFlags
      )}
      <div className="movie-detail-perfs">{perfs}</div>
    </>
  );
}

const _filterByFlags = (performance, subtitles, c3d, dubbing, originalCz) => {
  return (
    (subtitles &&
      performance.flags &&
      performance.flags.some((flag) => flag === "T")) ||
    (c3d &&
      performance.flags &&
      performance.flags.some((flag) => flag === "3D")) ||
    (dubbing &&
      performance.flags &&
      performance.flags.some((flag) => flag === "D")) ||
    (originalCz &&
      performance.flags &&
      performance.flags.some((flag) => flag === "OCZ"))
  );
};

const _renderSwitches = (
  setSubtitles,
  setDubbing,
  setC3d,
  setOriginalCz,
  uniqueFlags
) => {
  if (uniqueFlags.length > 1) {
    return (
      <div className="switch-labels">
        {_renderSwitch("Titulky", setSubtitles, "T", uniqueFlags)}
        {_renderSwitch("Dabing", setDubbing, "D", uniqueFlags)}
        {_renderSwitch("3D", setC3d, "3D", uniqueFlags)}
        {_renderSwitch("CZ zvuk", setOriginalCz, "OCZ", uniqueFlags)}
      </div>
    );
  }
};

const _renderSwitch = (text, setter, flag, uniqueFlags) => {
  if (uniqueFlags.includes(flag)) {
    return (
      <Switch
        checked={true}
        text={text}
        onClick={(checked) => setter(checked)}
      />
    );
  }
};

const _filterPerformances = (performances) => {
  const today = moment().format("YYYY-MM-DD");
  const now = moment().format("HH:mm");

  const todayPerformances = performances.find((perf) => perf.date === today);

  if (todayPerformances) {
    todayPerformances.performances = todayPerformances.performances.map(
      (perf) => {
        if (perf.date <= today && perf.time < now) perf.isObsolete = true;

        return perf;
      }
    );
  }
};

const _day = (day, subtitles, c3d, dubbing, originalCz, range) => {
  const from = range.from.toString().padStart(2, "0");
  const to = range.to.toString().padStart(2, "0");

  return day.performances
    .filter(
      (performance) =>
        _filterByRange(performance, from, to) &&
        _filterByFlags(performance, subtitles, c3d, dubbing, originalCz)
    )
    .map((performance) => (
      <Performance
        key={`perf-${performance.date}-${performance.time}-${performance.venueName}`}
        performance={performance}
      />
    ));
};

const _filterByRange = (performance, from, to) => {
  return performance.time >= from && performance.time <= to;
};

export default Performances;
