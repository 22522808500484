import React from "react";

function RangeInfo(props) {
  const { range } = props;

  if (!range || (range.from <= 7 && range.to === 24)) {
    return null;
  }

  return (
    <div className="range-info">
      {`Máte nastaven čas pro představení od ${range.from
        .toString()
        .padStart(2, "0")}:00 do ${range.to.toString().padStart(2, "0")}:00`}
    </div>
  );
}

export default RangeInfo;
