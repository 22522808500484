import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import MovieList from "./movie-list";
import Sort from "./sort";
import StickyHeadroom from "@integreat-app/react-sticky-headroom"
import Overlay from "./overlay";
import MovieDetail from "./movie-detail";
import Cookies from "universal-cookie";

const MoviesPage = (props) => {
  const [movieIds, setMovieIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const [scrollElement, setScrollElement] = useState(null);

  const movieDetail = useRef(null);
  
  useEffect(() => {
    const elem = document.getElementById("movies-container");
    setScrollElement(elem);
  }, []);

  useEffect(() => {
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }, [movieIds, scrollElement]);

  const fetchMovies = useCallback((searchParameters) => {
    setIsLoading(true);

    fetch(
      props.endpoint +
      `?q=${encodeURIComponent(searchParameters.query ?? "")}` +
      `&field=${encodeURIComponent(searchParameters.field ?? "rating")}` +
      `&ascending=${encodeURIComponent(searchParameters.ascending ?? true)}` +
      `&range=${encodeURIComponent(searchParameters.range ?? "")}` +
      `&date=${encodeURIComponent(searchParameters.date ?? "")}` +
      `&genres=${encodeURIComponent(searchParameters.genres ?? "")}`
    )
      .then((result) => result.json())
      .then((json) => {
        setMovieIds(json.movieIds);
        setTotal(json.total);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollBy(0, 2);
        window.scrollBy(0, -2);
      });
  }, [props.endpoint]);

  const fetchMoviesWithParams = useCallback((location) => {
    const params = new URLSearchParams(location.search);

    const cookies = new Cookies();
    const filterRange = cookies.get("filter-range");

    fetchMovies({
      query: params.get("q"),
      field: params.get("field"),
      ascending: params.get("ascending") === "false" ? "false" : "true",
      range: filterRange ?? params.get("range"),
      date: params.get("date"),
      genres: params.get("genres"),
    });
  }, [fetchMovies]);

  const openMovieDetail = useCallback((id) => {
    if (movieDetail.current) {
      movieDetail.current.showMovie(id);
    }
  }, [movieDetail]);

  useEffect(() => {
    fetchMoviesWithParams(window.location);
  }, [location, fetchMoviesWithParams]);

  return (
    <div>
      <div className="page">
        <StickyHeadroom className="headroom"
          parent={scrollElement}
          pinStart={0} height={100} scrollHeight={150}>
          <Sort
            showRange={props.showRange}
            premiereVisible={props.premiereVisible}
            filtered={movieIds.length}
            total={total}
          />
        </StickyHeadroom>
        <MovieList
          className="padding-on-top"
          movieIds={movieIds}
          onMovieDetailClick={openMovieDetail}
        />
        <Overlay isDisplayed={isLoading} />
        <MovieDetail
          ref={movieDetail}
          contextDate={props.contextDate}
          applyPerformanceFilter={props.showRange}
        />
      </div>
    </div>
  );
};

export default MoviesPage;
