import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import MovieShow from "./movie-show";
import MovieShowDummy from "./movie-show-dummy"
import MovieDetail from "./movie-detail";

function HomePage() {
  const [recommendedIds, setRecommendedIds] = useState([]);

  const movieDetail = useRef(null);

  useEffect(() => {
    const fetchMovieIds = async () => {
      try {
        const response = await fetch('/s-movies/');
        if (response.ok) {
          const data = await response.json();
          setRecommendedIds(data.movieIds.sort(() => 0.5 - Math.random()).slice(0, Math.min(data.movieIds.length, 3)));
        }
      } catch (error) {
        console.error("Error fetching movieIds:", error);
      }
    };

    fetchMovieIds();
  }, []);

  const openMovieDetail = useCallback((id) => {
    if (movieDetail.current) {
      movieDetail.current.showMovie(id);
    }
  }, [movieDetail]);  

  return (
    <div className="home-page">
      <div id="content-page-logo" className="logo-container">
        <img
          className="page-logo"
          src={process.env.PUBLIC_URL + "/img/vkine-header.svg"}
          alt="vkine.art logo"
        />
        <a href="/">
          <span>vkine</span><span className="highlight-text">.art</span>
        </a>
      </div>
      <nav>
        <ul>
          <li className="inactive-menu-item">
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 10H3M16 2V6M8 2V6M9 16L11 18L15.5 13.5M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <Link to="/dnes">v kinech</Link>
          </li>
          <li className="inactive-menu-item">
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.5 22V17M4.5 7V2M2 4.5H7M2 19.5H7M13 3L11.2658 7.50886C10.9838 8.24209 10.8428 8.60871 10.6235 8.91709C10.4292 9.1904 10.1904 9.42919 9.91709 9.62353C9.60871 9.8428 9.24209 9.98381 8.50886 10.2658L4 12L8.50886 13.7342C9.24209 14.0162 9.60871 14.1572 9.91709 14.3765C10.1904 14.5708 10.4292 14.8096 10.6235 15.0829C10.8428 15.3913 10.9838 15.7579 11.2658 16.4911L13 21L14.7342 16.4911C15.0162 15.7579 15.1572 15.3913 15.3765 15.0829C15.5708 14.8096 15.8096 14.5708 16.0829 14.3765C16.3913 14.1572 16.7579 14.0162 17.4911 13.7342L22 12L17.4911 10.2658C16.7579 9.98381 16.3913 9.8428 16.0829 9.62353C15.8096 9.42919 15.5708 9.1904 15.3765 8.91709C15.1572 8.60871 15.0162 8.24209 14.7342 7.50886L13 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <Link to="/novinky?field=premiere&ascending=true">co se chystá</Link>
          </li>
          <li className="inactive-menu-item">
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 11.5H14.5L13 14.5L11 8.5L9.5 11.5H8.5M11.9932 5.13581C9.9938 2.7984 6.65975 2.16964 4.15469 4.31001C1.64964 6.45038 1.29697 10.029 3.2642 12.5604C4.75009 14.4724 8.97129 18.311 10.948 20.0749C11.3114 20.3991 11.4931 20.5613 11.7058 20.6251C11.8905 20.6805 12.0958 20.6805 12.2805 20.6251C12.4932 20.5613 12.6749 20.3991 13.0383 20.0749C15.015 18.311 19.2362 14.4724 20.7221 12.5604C22.6893 10.029 22.3797 6.42787 19.8316 4.31001C17.2835 2.19216 13.9925 2.7984 11.9932 5.13581Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <Link to="/speciality">speciality</Link>
          </li>
          <li className="inactive-menu-item">
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <Link to="/vse">všechno</Link>
          </li>
        </ul>
      </nav>
      <h1>Doporučení pro dnešek</h1>
      <div className="top-movies">
        {recommendedIds.length > 0 ? (
          recommendedIds.slice(0, 3).map((id) => (
            <MovieShow id={id} key={`movie-key-${id}`} onMovieDetailClick={openMovieDetail}/>
          ))
        ) : (
          <>
            <MovieShowDummy />
            <MovieShowDummy />
            <MovieShowDummy />
          </>
        )}
      </div>
      <MovieDetail
          ref={movieDetail}
        />
    </div>
  );
}

export default HomePage;
