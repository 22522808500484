import React, { useState, useEffect } from "react";

function Switch(props) {
  const [isChecked, setChecked] = useState(props.checked);

  const handleClick = (e) => {
    const newState = !isChecked;
    setChecked(newState);

    e.stopPropagation();
    props.onClick(newState, props.switchKey);
  };

  const selectedClass = isChecked
    ? "switch-label switch-label-checked"
    : "switch-label";

  useEffect(() => {
      setChecked(props.checked);
  }, [props.checked]);

  return (
    <button className={selectedClass} onClick={(e) => handleClick(e)}>
      <span>{props.text}</span>
    </button>
  );
}

export default Switch;
