import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import RatingList from "./rating-list.js";
import Flags from "./flags.js";
import Release from "./movie-release.js";
import Premiere from "./premiere.js";
import MovieShowDummy from "./movie-show-dummy"

const Movie = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [movieName, setMovieName] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const [rating, setRating] = useState(null);
  const [released, setReleased] = useState(null);
  const [countries, setCountries] = useState([]);
  const [latestCzechPremiere, setLatestCzechPremiere] = useState(null);
  const [hasPerformance, setHasPerformance] = useState(false);

  const handleClick = (event) => {
    if (
      event.target &&
      (event.target.classList.contains("poster-img") ||
        event.target.classList.contains("poster-placeholder"))
    ) {
      event.stopPropagation();

      if (props.onClick) {
        props.onClick(event);
      }
    }
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  useEffect(() => {
    if (inView) {
      fetch(`/s-movie/${props.id}`)
        .then((result) => result.json())
        .then((json) => {
          setIsLoaded(true);
          setMovieName(json.czechName);
          setOriginalName(json.originalName);
          setPosterUrl(json.posterUrl);
          setRating(json.rating.ratings);
          setCountries(json.countries);
          setReleased(json.year);
          setLatestCzechPremiere(json.latestCzechPremiere);
          setHasPerformance(json.hasPerformance);
        });
    }
  }, [props.id, inView]);

  return (
    <div ref={ref}>{isLoaded ?
      (<div>{inView ?
        <article id={props.id} className="movie-frame">
          <div className="clickable-poster" onClick={handleClick}>
            {posterUrl && (
              <div className="img-fade">
                <div className="img-wrap">
                  <img
                    src={posterUrl}
                    alt={`Plakát filmu ${movieName}`}
                    className="poster-img"
                  />
                </div>
              </div>
            )}
            {isLoaded && !posterUrl && (
              <div className="img-fade">
                <div className="img-wrap">
                  <img
                    className="poster-placeholder"
                    src={process.env.PUBLIC_URL + "/img/poster-placeholder.svg"}
                    alt={`Plakát filmu ${movieName} není k dispozici`}
                  />
                </div>
              </div>
            )}
            <RatingList rating={rating} />
          </div>
          <div className="movie-info">
            <h1>{movieName}</h1>
            {originalName !== movieName && <h2>{originalName}</h2>}
            <div className="movie-flags">
              <Release released={released} />
              <Flags countries={countries} maxFlags={3} />
            </div>
          </div>
          {!hasPerformance && <Premiere date={latestCzechPremiere} />}
        </article>
        : null}</div>) :
      (<MovieShowDummy />)}</div>
  );
};

export default Movie;
