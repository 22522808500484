import ReactPlayer from "react-player";

function YouTubeTrailers({ trailers }) {
  return (
    <div className="player-wrapper">
      {trailers.length > 0 &&
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          stopOnUnmount={true}
          url={"https://www.youtube.com/watch?v=" + trailers[0].key}
        />
      }
    </div>
  );
}

export default YouTubeTrailers;
