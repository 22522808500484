import React, { useState, useEffect, useCallback } from "react";
import RatingList from "./rating-list.js";

const MovieShow = ({id, onMovieDetailClick}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [movieName, setMovieName] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const [rating, setRating] = useState(null);

  useEffect(() => {
    fetch(`/s-movie/${id}`)
      .then((result) => result.json())
      .then((json) => {
        setIsLoaded(true);
        setMovieName(json.czechName);
        setOriginalName(json.originalName);
        setPosterUrl(json.posterUrl);
        setRating(json.rating.ratings);
      });
  }, [id]);

  const handleClick = useCallback((id) => {
    if (onMovieDetailClick) {
      onMovieDetailClick(id);
    }
  }, [onMovieDetailClick]);

  return (
    <div>
      <article id={id} className="movie-frame clickable" onClick={() => handleClick(id)}>
        <div className="non-clickable-poster">
          {posterUrl && (
            <div className="img-fade">
              <div className="img-wrap">
                <img
                  src={posterUrl}
                  alt={`Plakát filmu ${movieName}`}
                  className="poster-img"
                />
              </div>
            </div>
          )}
          {isLoaded && !posterUrl && (
            <div className="img-fade">
              <div className="img-wrap">
                <img
                  className="poster-placeholder"
                  src={process.env.PUBLIC_URL + "/img/poster-placeholder.svg"}
                  alt={`Plakát filmu ${movieName} není k dispozici`}
                />
              </div>
            </div>
          )}
          <RatingList rating={rating} />
        </div>
        <div className="movie-info">
          <h1>{movieName}</h1>
          {originalName !== movieName && <h2>{originalName}</h2>}
        </div>
      </article>
    </div>
  );
};

export default MovieShow;
