import React from "react";
import PerformanceFlags from "./perf-flags";

const _peformanceLookupLink = function (cinema) {
  const text = `${cinema} praha - program`;
  return `https://duckduckgo.com/?q=${encodeURIComponent(text)} !`;
};

function Performance(props) {
  const { performance } = props;

  const opacity = performance.isObsolete ? 0.5 : 1.0;
  const lineThrough = performance.isObsolete ? "line-through" : null;

  const perfRender = (
    <div
      className="movie-detail-perf"
      style={{ opacity: opacity, textDecoration: lineThrough }}
    >
      <span className="movie-detail-perf-time">{performance.time} </span>
      <a
        href={_peformanceLookupLink(performance.venueName)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="movie-detail-venue-name">{performance.venueName}</span>
        <PerformanceFlags flags={performance.flags} />
      </a>
    </div>
  );

  return perfRender;
}

export default Performance;
