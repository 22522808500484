import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SortSwitch from "./sort-switch";
import PerformanceTime from "./performance-time";
import SortButton from "./sort-button";
import { DayPicker } from "react-day-picker";
import { cs } from "date-fns/locale";
import moment from "moment";
import ClickOutsideDetector from "./click-outside-detector";
import {
  cookieGetFilterRange,
  cookieSetFilterRange,
} from "../utilities/cookie-store";
import MobileNavigation from "./mobile-navigation";
import ClearableInput from "./clearable-input";

const day = {
  1: "Pondělí",
  2: "Úterý",
  3: "Středa",
  4: "Čtvrtek",
  5: "Pátek",
  6: "Sobota",
  7: "Neděle",
};

const Sort = (props) => {
  const defaultFilterRange = cookieGetFilterRange();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchText, setSearchText] = useState("");
  const [searchTextTrigger, setSearchTextTrigger] = useState("");
  const [minMaxTrigger, setMinMaxTrigger] = useState("");
  const [dateText, setDateText] = useState(getCurrentDateText(moment().format("YYYY-MM-DD")));
  const [selectedDays, setSelectedDays] = useState({ from: undefined, to: undefined });
  const [timeRange, setTimeRange] = useState(defaultFilterRange);
  const [sortField, setSortField] = useState("name");
  const [ascending, setAscending] = useState(true);

  const setSearchState = useCallback((searchParameters) => {
    const days = getDateRange(searchParameters.date);

    setAscending(searchParameters.ascending === "true");
    setSortField(searchParameters.field);
    setSearchText(searchParameters.query);
    const randomString = Math.random().toString(36).substring(2);
    setSearchTextTrigger(randomString);
    setMinMaxTrigger(randomString);
    setTimeRange(searchParameters.range);
    setSelectedDays(days);
    setDateText(
      getCurrentDateText(
        searchParameters.date || moment().startOf("day").format("YYYY-MM-DD")
      )
    );
  }, []);

  const sortClick = useCallback((changeEvent) => {
    const searchParameters = getParametersFromUrl(defaultFilterRange, navigate);
    if (changeEvent.target.value === searchParameters.field) {
      searchParameters.ascending =
        searchParameters.ascending === "true" ? "false" : "true";
    } else {
      searchParameters.field = changeEvent.target.value;
    }
    setParametersToUrl(searchParameters, navigate);
    setSearchState(searchParameters);
  }, [defaultFilterRange, navigate, setSearchState]);

  const textChanged = useCallback((text) => {
    setSearchText(text);
    const searchParameters = getParametersFromUrl(defaultFilterRange, navigate);
    searchParameters.query = text;
    setParametersToUrl(searchParameters, navigate);
  }, [defaultFilterRange, navigate]);

  const onRangeChanged = useCallback((range) => {
    const searchParameters = getParametersFromUrl(defaultFilterRange, navigate);
    searchParameters.range = `${range.from}-${range.to}`;
    cookieSetFilterRange(searchParameters.range);
    setParametersToUrl(searchParameters, navigate);
  }, [defaultFilterRange, navigate]);

  const onCalendarSelect = useCallback((range) => {
    if (range && range.from && range.to) {
      const dateFrom = moment(range.from);
      const dateTo = moment(range.to ?? range.from);
      const searchParameters = getParametersFromUrl(defaultFilterRange, navigate);
      searchParameters.date = `${dateFrom.format("YYYY-MM-DD")},${dateTo.format("YYYY-MM-DD")}`;
      setParametersToUrl(searchParameters, navigate);
      setSearchState(searchParameters);
      hideCalendar();
    }
  }, [defaultFilterRange, navigate, setSearchState]);

  const handleCalendarSelect = useCallback((range) => {
    setSelectedDays(range);
    onCalendarSelect(range);
  }, [onCalendarSelect]);

  useEffect(() => {
    const searchParameters = getParametersFromUrl(defaultFilterRange, navigate);
    setSearchState(searchParameters);
  }, [setSearchState, defaultFilterRange, navigate, location]);

  return (
    <>
      <MobileNavigation />
      <SortSwitch
        value="rating"
        active={sortField === "rating"}
        ascending={ascending}
        onClick={sortClick}
        img="star.svg"
      />
      <SortSwitch
        value="name"
        active={sortField === "name"}
        ascending={ascending}
        onClick={sortClick}
        img="az.svg"
      />
      {props.premiereVisible && (
        <SortSwitch
          value="premiere"
          active={sortField === "premiere"}
          ascending={ascending}
          onClick={sortClick}
          img="curtains.svg"
        />
      )}
      {props.showRange && (
        <>
          <SortButton
            img="calendar.svg"
            value={dateText}
            onClick={showCalendar}
          />
          <div className="calendar-container">
            <div className="calendar-background">
              <ClickOutsideDetector
                listen
                onClickOutside={hideCalendar}
              >
                <DayPicker
                  className="day-picker"
                  locale={cs}
                  mode="range"
                  min={0}
                  max={100}
                  selected={selectedDays}
                  disabled={{ before: new Date() }}
                  numberOfMonths={2}
                  onSelect={handleCalendarSelect}
                />
              </ClickOutsideDetector>
            </div>
          </div>
        </>
      )}
      <div className="search-label">
        <ClearableInput
          className="search-query"
          minLength={2}
          placeholder="hledej"
          text={searchText}
          textResetTrigger={searchTextTrigger}
          onTextChange={textChanged}
        />
      </div>
      {props.showRange && (
        <PerformanceTime
          range={timeRange}
          min={7}
          max={24}
          minMaxTrigger={minMaxTrigger}
          onRangeChange={onRangeChanged}
        />
      )}
    </>
  );
};

export default Sort;

const getParametersFromUrl = (defaultFilterRange, navigate) => {
  const today = moment().format("YYYY-MM-DD");
  const params = {
    field: "rating",
    ascending: "false",
    query: "",
    range: defaultFilterRange ?? "7-24",
    date: `${today},${today}`,
  };

  if (navigate) {
    const urlParams = new URLSearchParams(window.location.search);
    params.ascending =
      urlParams.get("ascending") === "false" ? "false" : "true";
    params.field = urlParams.get("field") || "rating";
    params.query = urlParams.get("q") || "";
    params.range = urlParams.get("range") || params.range;
    params.date = urlParams.get("date") || params.date;
    params.genres = urlParams.get("genres");
  }

  return params;
};

const setParametersToUrl = (searchParameters, navigate) => {
  if (navigate) {
    const params = new URLSearchParams(window.location.search);
    params.delete("field");
    params.delete("ascending");
    params.delete("q");
    params.delete("range");
    params.delete("date");
    params.delete("genres");

    if (searchParameters.field) params.set("field", searchParameters.field);
    if (searchParameters.ascending) params.set("ascending", searchParameters.ascending);
    if (searchParameters.query) params.set("q", searchParameters.query);
    if (searchParameters.range) params.set("range", searchParameters.range);
    if (searchParameters.date) params.set("date", searchParameters.date);
    if (searchParameters.genres) params.set("genres", searchParameters.genres);

    navigate("?" + params.toString(), { replace: true });
  }
};

const getCurrentDateText = (date) => {
  const dates = date.split(",");
  const dateFrom = dates[0];
  const dateTo = dates.length > 1 ? dates[1] : dateFrom;
  const mDateFrom = moment(dateFrom);
  const mDateTo = moment(dateTo);
  const today = moment().startOf("day").format("YYYY-MM-DD");
  const mYesterday = moment(dateFrom).subtract(1, "days");
  const selectedDays = moment.duration(mDateTo.diff(mDateFrom));
  const days = Math.round(selectedDays.asDays());

  let calendarText = "";

  if (today === mDateFrom.format("YYYY-MM-DD")) {
    calendarText = "dnes";
  } else if (today === mYesterday.format("YYYY-MM-DD")) {
    calendarText = "zítra";
  } else {
    calendarText = `${day[mDateFrom.isoWeekday()]} ${mDateFrom.format("DD.MM.")}`;
  }

  if (days > 0) {
    calendarText += ` +${days}`;
  }

  return calendarText;
};

const getDateRange = (dateRange) => {
  if (!dateRange) return null;
  const dates = dateRange.split(",");
  const dateFrom = dates[0];
  const dateTo = dates.length > 1 ? dates[1] : dateFrom;
  const mDateFrom = moment(dateFrom);
  const mDateTo = moment(dateTo);

  return { from: mDateFrom.toDate(), to: mDateTo.toDate() };
};

const showCalendar = () => {
  const modal = document.querySelector(".calendar-container");
  document.documentElement.classList.add("is-locked");
  modal.style.display = "block";
  modal.addEventListener("pointermove", () => { });
};

const hideCalendar = () => {
  const modal = document.querySelector(".calendar-container");
  modal.removeEventListener("pointermove", () => { });
  document.documentElement.classList.remove("is-locked");
  modal.style.display = "none";
};