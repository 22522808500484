import React from "react";

class RatingBig extends React.PureComponent {
  render() {
    let pillColor = "black";
    let textColor = "white";

    switch (this.props.rating.database) {
      case "IMDB":
        pillColor = "#ffc800";
        textColor = "black";
        break;
      case "ČSFD":
        pillColor = "rgb(186, 3, 5)";
        break;
      case "TMDB":
        pillColor = "rgb(34, 82, 138)";
        break;
      case "FFFILM":
        pillColor = "rgb(195, 190, 255)";
        textColor = "black";
        break;
      default:
        return "";
    }

    return (
      <>
        {this.props.rating ? (
          <a href={this.props.rating.url} target="_blank" rel="noopener noreferrer">
            <div className="rating-big">
              <div
                className="rating-pill"
                style={{ backgroundColor: pillColor }}
              >
                <p className="rating-database" style={{ color: textColor }}>
                  {this.props.rating.database}
                </p>
              </div>
              <p className="rating-big-value">
                {this.props.rating.value ?? "--"}
                {this.props.rating.value && (
                  <span className="rating-big-percent">%</span>
                )}
              </p>
            </div>
          </a>
        ) : null}
      </>
    );
  }
}

export default RatingBig;
